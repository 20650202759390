import { render, staticRenderFns } from "./HistoricalJobs.vue?vue&type=template&id=31d2f2bb&scoped=true&"
import script from "./HistoricalJobs.js?vue&type=script&lang=js&"
export * from "./HistoricalJobs.js?vue&type=script&lang=js&"
import style0 from "./HistoricalJobs.scss?vue&type=style&index=0&id=31d2f2bb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d2f2bb",
  null
  
)

export default component.exports