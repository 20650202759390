import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import Notifications from 'vue-notification'
import VueSweetalert2 from 'vue-sweetalert2';
import { BootstrapVue } from 'bootstrap-vue'
import i18n from './i18n';
import VueGtm from '@gtm-support/vue2-gtm';

import 'bootstrap';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'material-design-icons';
import 'material-design-icons/iconfont/material-icons.css'

// KEEP THIS LINE, OTHERWISE THE BUILD MAY FAIL BECAUSE DOCKER CANNOT REPLACE THE ENV.
const gtmEnabled = process.env.VUE_APP_GOOGLE_TAG_MANAGER_ENABLED;

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID ?? '',
  defer: false, 
  compatibility: false,
  nonce: '2726c7f26c',
  enabled: gtmEnabled === 'true',

});

Vue.config.productionTip = false
Vue.use(Notifications)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
