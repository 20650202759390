import { mapActions } from "vuex"

export default {
    name: 'Signin',
    data () {
      return {
        email: '',
        password: '',
        test: process.env.VUE_APP_API_URL
      }
    },
    created () {
      this.checkSignedIn()
    },
    methods: {
        ...mapActions('authentication', [
            'retrieveSignedInStatus',
            'signin'
        ]),
        async checkSignedIn () {
            const isSignedIn = await this.retrieveSignedInStatus()
            if (isSignedIn == true) {
              const roles = JSON.parse(localStorage.getItem('roles'))
              if (Object.keys(roles).length > 1) {
                  this.$router.replace('/rol').catch(()=>{})
              } else if (roles['admin']) {
                  this.$router.replace('/admin').catch(()=>{})
              } else if (roles['candidate']) {
                  this.$router.replace('/kandidaat').catch(()=>{})
              } else if (roles['youth_advisor']) {
                  this.$router.replace('/dashboard').catch(()=>{})
              } else if (roles['business']) {
                  this.$router.replace('/bedrijf').catch(()=>{})
              } else if (roles['account_manager']) {
                  this.$router.replace('/account-manager').catch(()=>{})
              } else {
                  this.$notify({
                    group: 'data',
                    title: 'Niet geautoriseerd',
                    text: `Er zijn geen rollen gevonden`,
                    type: 'error'
                  });
                  localStorage.removeItem('csrf')
                  localStorage.removeItem('signedIn')
                  localStorage.removeItem('roles')
              }
            }
        },
        goToHomePage() {
            this.$router.push({ name: 'HomePage' })
        },
        goToForgotPassword() {
            this.$router.push({ name: 'ForgotPassword'})
        }
    },
}