<template>
  <div id="app">
    <notifications group="data" />
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style lang="scss">
@import "./assets/styles/global.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.btn-primary {
  background-color: $base-color !important;
  border-color: $base-color !important;
}

.btn-success {
  background-color: $highlight-color !important;
  border-color: $highlight-color !important;
}

.btn-default {
  background-color: #ECE8FD !important;
  border-color: #ECE8FD !important;
  color: $base-color !important;
}

.material-icons {
  vertical-align: middle;
  cursor: pointer;
}

.row-header {
    justify-content: space-between;
    display: flex;
}
</style>

